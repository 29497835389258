import { addWeeks, format, isValid } from 'date-fns';
import dayjs from 'dayjs';

import { permissionEnum } from './types';

export const checkPermissions = (permission: permissionEnum): boolean => {
  console.log('🚀 ~ checkPermissions ~ permission:', permission);
  return true;
};

export const convertTo24HourFormat = (time12h: string): string => {
  const [time, modifier] = time12h.split(' ');
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = (parseInt(hours, 10) + 12).toString();
  }

  return `${hours.padStart(2, '0')}:${minutes}`;
};

export function convertTo12HourFormat(time24: string): string {
  const [hours, minutes] = time24.split(':').map(Number);

  if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
    return 'Invalid time format';
  }

  const period = hours < 12 ? 'AM' : 'PM';

  let hours12 = hours % 12;
  hours12 = hours12 === 0 ? 12 : hours12;

  const time12 = `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;

  return time12;
}

export function createTimeSlots(inputTime: string): { name: string; id: string }[] {
  const [timeData, modifier] = inputTime.split(/(?=[AP]M)/i);
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = timeData.split(':').map(Number);

  if (modifier.toLowerCase() === 'pm' && hours !== 12) {
    hours += 12;
  } else if (modifier.toLowerCase() === 'am' && hours === 12) {
    hours = 0;
  }

  const date = new Date();
  date.setHours(hours, minutes, 0);

  const timeSlots = [];

  for (let i = -1; i <= 1; i++) {
    const newTime = new Date(date.getTime() + i * 30 * 60000);
    const name = formatTimeName(newTime);
    const id = formatTimeId(newTime);
    timeSlots.push({ name, id });
  }

  return timeSlots;
}

function formatTimeName(date: Date): string {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // Convert 0 to 12 for 12 AM/PM
  const minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();
  return `${hours}:${minutesStr} ${ampm}`;
}

function formatTimeId(date: Date): string {
  const hours = date.getHours().toString().padStart(2, '0'); // Ensure two-digit hour
  const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure two-digit minutes
  return `${hours}:${minutes}`;
}

export function isStartTimeSmaller(startTime: string, endTime: string): boolean {
  const startTime24 = convertTo24HourFormat(startTime);
  const endTime24 = convertTo24HourFormat(endTime);

  return startTime24 < endTime24;
}

export const getPosition = (key: string) => {
  switch (key) {
    case 'type':
      return { gridRow: '1/1', gridColumn: '3/3' };
    case 'floorNumber':
      return { gridRow: '2/2', gridColumn: '2/2' };
    case 'capacity':
      return { gridRow: '2/2', gridColumn: '3/3' };
    case 'mediaSupport':
      return { gridRow: '2/2', gridColumn: '1/1' };
    case 'location':
      return { gridRow: '1/1', gridColumn: '2/2' };
    case 'selectedDate':
      return { gridRow: '1/1', gridColumn: '1/1' };
  }
};

export function getNextSixMondays(dateString: string): string[] {
  console.log('Input date string:', dateString);

  const localTimeZoneOffset = new Date().getTimezoneOffset();

  // Adjust the parsed date to the local timezone
  const adjustedInputDate = dayjs(dateString)
    .utcOffset(-localTimeZoneOffset, true) // Apply offset and keep time intact
    .toDate();

  // Validate the parsed date
  if (!isValid(adjustedInputDate)) {
    throw new Error(`Invalid date string: ${dateString}. Ensure the format is 'EEE, dd MMM yyyy HH:mm:ss GMT'.`);
  }

  console.log('Parsed date (before offset adjustment):', adjustedInputDate);

  // Calculate the local timezone offset in minutes

  console.log('Adjusted date (with local timezone offset):', adjustedInputDate);

  // Generate the next 6 Mondays
  const futureMondays = Array.from({ length: 6 }, (_, i) => addWeeks(adjustedInputDate, i + 1));

  // Format the dates to the desired format
  return futureMondays.map(date => format(date, 'EEE MMM do'));
}

// Function to calculate recurring dates in TypeScript
export function getRecurringDates(startDateString: string, recurrence: string, duration: string): string[] {
  // Map recurrence intervals to number of days
  const recurrenceDaysMap: { [key: string]: number } = {
    '1-week': 7,
    '2-weeks': 14,
    '3-weeks': 21,
    '4-weeks': 28,
    '5-weeks': 35,
    monthly: 30, // Approximate for simplicity
  };

  // Map duration to number of days
  const durationDaysMap: { [key: string]: number | ((date: Date) => number) } = {
    '1-month': 30,
    '2-months': 60,
    '3-months': 90,
    '6-months': 180,
    '9-months': 270,
    endOfYear: (date: Date) => {
      const endOfYear = new Date(date.getFullYear(), 11, 31);
      return Math.ceil((endOfYear.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));
    },
  };

  // Parse the start date
  const startDate = new Date(startDateString);

  if (isNaN(startDate.getTime())) {
    return [];
  }

  // Get recurrence interval in days
  const recurrenceDays = recurrenceDaysMap[recurrence];

  if (!recurrenceDays) {
    return [];
  }

  // Get duration in days
  let durationDays = durationDaysMap[duration];

  if (typeof durationDays === 'function') {
    durationDays = durationDays(startDate);
  }

  if (!durationDays) {
    return [];
  }

  // Helper function to format dates
  function formatDate(date: Date): string {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = days[date.getDay()];
    const month = months[date.getMonth()];
    const dateNum = date.getDate();

    const suffix =
      dateNum % 10 === 1 && dateNum !== 11
        ? 'st'
        : dateNum % 10 === 2 && dateNum !== 12
        ? 'nd'
        : dateNum % 10 === 3 && dateNum !== 13
        ? 'rd'
        : 'th';

    return `${day} ${month} ${dateNum}${suffix}`;
  }

  // Generate dates
  const dates: string[] = [];
  let currentDate = new Date(startDate);
  dates.push(formatDate(currentDate));

  // eslint-disable-next-line no-constant-condition
  while (true) {
    currentDate = new Date(currentDate);
    currentDate.setDate(currentDate.getDate() + recurrenceDays);

    const diff = (currentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

    if (diff >= durationDays) {
      break;
    }

    dates.push(formatDate(currentDate));
  }

  return dates;
}

export function getRecurringDateRanges(
  startDateString: string,
  endDateString: string,
  recurrence: string,
  duration: string
): string[] {
  // Map recurrence intervals to number of days
  const recurrenceDaysMap: { [key: string]: number } = {
    '1-week': 7,
    '2-weeks': 14,
    '3-weeks': 21,
    '4-weeks': 28,
    '5-weeks': 35,
    monthly: 30, // Approximate for simplicity
  };

  // Map duration to number of days
  const durationDaysMap: { [key: string]: number | ((date: Date) => number) } = {
    '1-month': 30,
    '2-months': 60,
    '3-months': 90,
    '6-months': 180,
    '9-months': 270,
    endOfYear: (date: Date) => {
      const endOfYear = new Date(date.getFullYear(), 11, 31);
      return Math.ceil((endOfYear.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));
    },
  };

  // Parse the start and end dates
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return [];
  }

  // Calculate the length of the date range in days
  const rangeLength = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;

  // Get recurrence interval in days
  const recurrenceDays = recurrenceDaysMap[recurrence];

  if (!recurrenceDays) {
    return [];
  }

  // Get duration in days
  let durationDays = durationDaysMap[duration];

  if (typeof durationDays === 'function') {
    durationDays = durationDays(startDate);
  }

  if (!durationDays) {
    return [];
  }

  // Helper function to format dates
  function formatDate(date: Date): string {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const day = days[date.getDay()];
    const month = months[date.getMonth()];
    const dateNum = date.getDate();

    const suffix =
      dateNum % 10 === 1 && dateNum !== 11
        ? 'st'
        : dateNum % 10 === 2 && dateNum !== 12
        ? 'nd'
        : dateNum % 10 === 3 && dateNum !== 13
        ? 'rd'
        : 'th';

    return `${day} ${month} ${dateNum}${suffix}`;
  }

  // Generate date ranges
  const dateRanges = [];
  const currentStartDate = new Date(startDate);

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const currentEndDate = new Date(currentStartDate);
    currentEndDate.setDate(currentStartDate.getDate() + rangeLength - 1);

    const diff = (currentStartDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

    if (diff >= durationDays) {
      break;
    }

    dateRanges.push(`${formatDate(currentStartDate)} - ${formatDate(currentEndDate)}`);

    currentStartDate.setDate(currentStartDate.getDate() + recurrenceDays);
  }

  return dateRanges;
}

export function scaleTime(start: string, stop: string) {
  function parseTime(timeStr: string) {
    const parsedTime = dayjs(timeStr, 'h:mm A');
    return parsedTime.hour() + parsedTime.minute() / 60;
  }

  function formatAMPM(hour: number) {
    return dayjs()
      .hour(Math.floor(hour))
      .minute((hour % 1) * 60)
      .format('h:mm A');
  }

  function snapToNearestHalfHour(hour: number) {
    const minutes = (hour % 1) * 60;

    if (minutes < 15) {
      return Math.floor(hour) + 0; // Snap to :00
    } else if (minutes >= 15 && minutes < 45) {
      return Math.floor(hour) + 0.5; // Snap to :30
    } else {
      return Math.floor(hour) + 1; // Snap to the next hour (:00)
    }
  }

  const startHour = parseTime(start);
  const stopHour = parseTime(stop);
  const range = stopHour - startHour;

  let data;

  if (range === 0) {
    // If start and end times are the same
    data = [
      formatAMPM(startHour),
      formatAMPM(startHour + 0.5), // Add 30 minutes
    ];
  } else if (range === 0.5) {
    // If the time difference is 30 minutes
    data = [
      formatAMPM(startHour),
      formatAMPM(stopHour),
      formatAMPM(stopHour + 0.5), // Add 30 minutes to the end time
    ];
  } else if (range === 1) {
    // If the time difference is 1 hour
    data = [
      formatAMPM(startHour),
      formatAMPM(startHour + 0.5), // Add 30 minutes
      formatAMPM(stopHour),
    ];
  } else {
    // Default logic for other cases
    const step = range / 4; // Divide the range into 4 equal parts
    data = [
      formatAMPM(startHour),
      formatAMPM(snapToNearestHalfHour(startHour + step)),
      formatAMPM(snapToNearestHalfHour(startHour + 2 * step)),
      formatAMPM(snapToNearestHalfHour(startHour + 3 * step)),
      formatAMPM(stopHour),
    ];
  }

  return data;
}
